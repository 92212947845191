'use strict';

import {AbstractPSWidgetForm} from "../abstract-form";
import {InternalPSWidgetFavouritesLink} from "./internal-favourites-link";
import {Constants} from "../constants";
import {PSWidgetLocationCombobox} from "../suggestions/location/location-combobox";

export class InternalPSWidgetForm extends AbstractPSWidgetForm {

    constructor(language, luminoWidgetController, suggestionsData, favouritesCount, googleAPIKey, uniqueId, customizationOptions, keywordLogging) {

        super(language, luminoWidgetController, customizationOptions.defaultSpecialty, customizationOptions.defaultLocation,
            customizationOptions.defaultCondition, customizationOptions.defaultService, googleAPIKey, customizationOptions.verticalOrientation,
            customizationOptions.showHeaderText, uniqueId, customizationOptions.searchModes,
            customizationOptions.textSearchPlaceholderText, customizationOptions.textSearchLabelText,
            customizationOptions.useConfigSpecialtySorting, keywordLogging, suggestionsData);

        this._initialFavouritesCount = favouritesCount;

        this._searchModes = customizationOptions.searchModes;
        this._headerText = customizationOptions.headerText;
        this._footerText = customizationOptions.footerText;
        this._topLogo = customizationOptions.topLogo;
        this._headerLogo = customizationOptions.headerLogo;
        this._showMyAppointments = customizationOptions.showMyAppointments;
        this._locationPlaceholderText = customizationOptions.locationPlaceholderText;
        this._locationLabelText = customizationOptions.locationLabelText;

        this._enableFavourites = customizationOptions.enableFavourites;
        this._theme = customizationOptions.theme;
    }

    bindToDOM(parentDOMElement) {

        super.bindToDOM(parentDOMElement);

        /*if(this.isAppointmentsEnabled()) {
            this._appointmentsElement = new InternalPSWidgetAppointmentsLink(this._language);
            this._appointmentsElement.bindToDOM(this._domElement.querySelector('div[data-lumino-widget-id="lumino-widget-appointments-field"]'));
        }*/

        if(this.isFavouritesEnabled()) {
            this._favouritesElement = new InternalPSWidgetFavouritesLink(this._language, this.getInitialFavouritesCount());
            this._favouritesElement.bindToDOM(this._domElement.querySelector('div[data-lumino-widget-id="lumino-widget-favourites-field"]'));
        }

        const locationOptions = {
            googleAPIKey: this._googleAPIKey,
            defaultLocation: this.getDefaultLocation(),
            locationPlaceholderText: this._locationPlaceholderText,
            locationLabelText: this._locationLabelText,
            showLocationIcon: false,
            enableGeolocation: true
        };

        this._locationCombobox = new PSWidgetLocationCombobox(this._language, this._uniqueId, locationOptions);

        this.bindLocationFieldToDOM();

        this._domElement.addEventListener('goToFavourites', (e) => {
            e.preventDefault();
            this._controller.goToFavourites();
        });

        this._domElement.addEventListener('goToMyAppointments', (e) => {
            e.preventDefault();
            // this._controller.goToMyAppointments();
        });

        this._domElement.addEventListener('geolocationClick', () => {
            this._controller.triggerGeoLocationClickAnalytics();
            this._controller.logNavigationEvent("geolocation");
        });

        if(this.isSecureTheme()) {
            this._domElement.style.setProperty("--lumino-suggestion-hover-bg", "#FBEFCC");
        }
    }

    isSecureTheme() {
        if(!this._theme) {
            return false;
        }

        return this._theme.toLowerCase() === Constants.THEME_SECURE.toLowerCase();
    }

    updateFavouritesCount(favouritesCount) {
        this._favouritesElement?.updateFavouritesCount(favouritesCount);
    }

    getFavouritesCount() {
        return this._favouritesElement?.getFavouritesCount();
    }

    getInitialFavouritesCount() {
        return this._initialFavouritesCount;
    }

    renderAppointmentsLink() {
        return ``;

        /*if(!this.isAppointmentsEnabled()) {
            return ``;
        }

        return `<div class="lumino-widget-appointments-wrapper" data-lumino-widget-id="lumino-widget-appointments-wrapper">
                    <div class="lumino-widget-appointments-field" data-lumino-widget-id="lumino-widget-appointments-field"></div>
                </div>`;*/
    }

    renderFavouritesLink() {
        if(!this.isFavouritesEnabled()) {
            return ``;
        }

        return `<div class="lumino-widget-favourites-wrapper" data-lumino-widget-id="lumino-widget-favourites-wrapper">
                    <div class="lumino-widget-favourites-field" data-lumino-widget-id="lumino-widget-favourites-field"></div>
                </div>`;
    }

    renderHiddenInputFields() {
        return `
            <input type="hidden" name="address" value="">
            <input type="hidden" name="geolocationaddress" value="">
            <input type="hidden" name="searchvalue" value="">
            <input type="hidden" name="searchmode" value="">
            <input type="hidden" name="skipinitialsearchaalog" value=true>
            <input type="hidden" name="skipinitialsearchmumlog" value=true>
            <input type="hidden" name="analyticssearchid">
            <input type="hidden" name="linkedfrom" value="${ this._controller.getLinkedFrom() ? this._controller.getLinkedFrom() : "" }">
            <input type="hidden" name="sponsorid" value="">
            <input type="hidden" name="suggestionsection" value="">
        `;
    }

    prepareFormForSearchAndSubmit(attempt) {

        const suggestionToUse = attempt.getSuggestionToUse();

        let searchMode = "";
        if(suggestionToUse.getType() === Constants.SUGGESTION_TYPE_SPECIALTY) {
            searchMode = Constants.SEARCH_MODE_SPECIALTY;
        } else if(suggestionToUse.getType() === Constants.SUGGESTION_TYPE_NAME) {
            searchMode = Constants.SEARCH_MODE_NAME;
        } else if(suggestionToUse.getType() === Constants.SUGGESTION_TYPE_CONDITION) {
            searchMode = Constants.SEARCH_MODE_CONDITION;
        } else if(suggestionToUse.getType() === Constants.SUGGESTION_TYPE_SERVICE) {
            searchMode = Constants.SEARCH_MODE_SERVICE;
        }

        this._domElement.querySelector("input[name='searchvalue']").value = suggestionToUse.getValue();
        this._domElement.querySelector("input[name='searchmode']").value = searchMode;
        this._domElement.querySelector("input[name='suggestionsection']").value = this.getController().getSuggestedSectionFromAttemptInformation(attempt);

        if(this.getController().getNextAnalyticsSearchId()) {
            this._domElement.querySelector("input[name='analyticssearchid']").value = this.getController().getNextAnalyticsSearchId();
        }
        this._domElement.querySelector("input[name='address']").value = this.getLocationValue();

        let geolocationAddress = "";
        if(this.getGeolocationAddress()) {
            geolocationAddress = this.getGeolocationAddress();
        }
        this._domElement.querySelector("input[name='geolocationaddress']").value = geolocationAddress;

        this.submitForm();
    }

    prepareFormForFavouritesAndSubmit() {
        this._domElement.querySelector("input[name='address']").value = "";
        this._domElement.querySelector("input[name='geolocationaddress']").value = "";
        this._domElement.querySelector("input[name='searchvalue']").value = "";
        this._domElement.querySelector("input[name='searchmode']").value = Constants.SEARCH_MODE_FAVOURITES;

        if(this.getController().getNextAnalyticsSearchId()) {
            this._domElement.querySelector("input[name='analyticssearchid']").value = this.getController().getNextAnalyticsSearchId();
        }

        this.submitForm();
    }

    submitForm() {

        this._domElement.querySelector("input[name='linkedfrom']").value = this._controller.getLinkedFrom();
        this._domElement.querySelector("input[name='sponsorid']").value = this._controller.getSponsorId();

        document.querySelector('[data-lumino-widget-id="lumino-widget-form"]').submit();
    }

    renderHeader() {

        if (!this._showHeaderText) {
            return ``;
        }

        return `<div class="lumino-widget-header-text-wrapper" data-lumino-widget-id="lumino-widget-header-text-wrapper">
                    ${this.renderTopLogo()}
                    ${this.renderHeaderLogo()}
                    <h2 data-lumino-widget-id="lumino-widget-cta-text-wrapper" class="lumino-widget-cta-text">${this._headerText ? this._headerText : this.getLocalizedLabel("headerText")}</h2>
                </div>`;
    }

    renderFooterText() {
        if(!this._footerText) {
            return ``;
        }

        return `<div class="lumino-widget-footer-text-wrapper"><p class="lumino-widget-footer-text" data-lumino-widget-id="lumino-widget-footer-text">${this._footerText}</p></div>`;
    }

    renderHeaderLogo() {
        if(!this._headerLogo) {
            return ``;
        }

        return `<img src="${this._headerLogo}" class="lumino-widget-header-logo" data-lumino-widget-id="lumino-widget-header-logo" alt="">`;
    }

    renderTopLogo() {
        if(!this._topLogo) {
            return ``;
        }

        return `<img src="${this._topLogo}" class="lumino-widget-top-logo" data-lumino-widget-id="lumino-widget-top-logo" alt="">`;
    }

    render() {
        return `<form ${ this._controller.getSearchActionType() === Constants.SEARCH_ACTION_TYPE_FORM_ACTION ? `action="${this._controller.getSecureProviderSearchPath()}" method="post"` : `` } autocomplete="off" class="lumino-widget-wrapper ${ this.isSecureTheme() ? `lumino-widget-secure-mode` : `` } ${this._verticalOrientation ? `lumino-widget-vertical-orientation` : ``}" data-lumino-widget-id="lumino-widget-form">
                    ${ this.renderHeader() }
                    <div class="lumino-widget-links-wrapper">
                         ${ this.renderAppointmentsLink() }                        
                         ${ this.renderFavouritesLink() }
                    </div>
                    ${ this.renderInputsAndSearchButton() }
                    ${ this.renderFooterText() }
                    ${ this.renderHiddenInputFields() }
                </form>`;
    }
}
