'use strict';

import {InternalPSWidgetBase} from "./internal-base";

new InternalPSWidgetBase();

if (document.readyState === "complete" || (document.readyState !== "loading" && !document.documentElement.doScroll)) {
    internalFormWidgetInit();
} else {
    document.addEventListener("DOMContentLoaded", internalFormWidgetInit);
}
