'use strict';

import {InternalPSWidgetForm} from "./internal-form";
import {AbstractPSWidgetController} from "../abstract-controller";
import {Constants} from "../constants";
import AALogger from "../aa-logger";
import {Utils} from "../utils";
import {MUMLogger} from "../mum-logger";
import {MUMSearchKeywordInfo} from "../mum-search-keyword-info";
import {MUMSearchErrorInfo} from "../mum-search-error-info";

export class InternalPSWidgetController extends AbstractPSWidgetController {

    constructor(luminoWidgetInstance, formInfo, googleApiKey, verticalOrientation, showHeaderText, uniqueId) {
        super(luminoWidgetInstance);

        this._topLogo = this._widgetInstance.getAttribute('data-lumino-top-logo');
        this._headerLogo = this._widgetInstance.getAttribute('data-lumino-header-logo');
        this._headerText = this._widgetInstance.getAttribute('data-lumino-header-text');
        this._footerText = this._widgetInstance.getAttribute('data-lumino-footer-text');

        this._locationPlaceholderText = this._widgetInstance.getAttribute('data-lumino-location-placeholder');
        this._locationLabelText = this._widgetInstance.getAttribute('data-lumino-location-label');

        this._specialtySearchPlaceholderText = this._widgetInstance.getAttribute('data-lumino-specialty-search-placeholder');
        this._specialtySearchLabelText = this._widgetInstance.getAttribute('data-lumino-specialty-search-label');

        this._textSearchPlaceholderText = this._widgetInstance.getAttribute('data-lumino-text-search-placeholder');
        this._textSearchLabelText = this._widgetInstance.getAttribute('data-lumino-text-search-label');

        this._theme = this._widgetInstance.getAttribute('data-lumino-theme');

        if(this._headerText === "") {
            showHeaderText = false;
        }

        this._targetNewTab = this._widgetInstance.hasAttribute('data-lumino-target-new-tab');

        this._enableFavourites = this._widgetInstance.hasAttribute('data-lumino-enable-favourites');
        this._searchModes = this.getSearchModesArrayForInitValue(this._widgetInstance.getAttribute('data-lumino-search-modes'), this._enableFavourites);

        this._searchActionType = this._widgetInstance.getAttribute('data-lumino-search-action-type');
        if(!this._searchActionType) {
            this._searchActionType = Constants.SEARCH_ACTION_TYPE_FORM_ACTION;
        }

        this._testHost = this._widgetInstance.getAttribute('data-lumino-test-host');
        this._source = "";
        this._linkedFrom = this._widgetInstance.getAttribute('data-lumino-linkedfrom');
        this._searchContextLocation = this._linkedFrom;
        this._version = "";

        this._mumLogger = new MUMLogger();

        this.initMUMPublicSessionId();
        if(this._searchActionType === Constants.SEARCH_ACTION_TYPE_FORM_ACTION || this._searchActionType === Constants.SEARCH_ACTION_TYPE_REDIRECT) {
            // for non-PS page, need to generate an analytics search id to be able to log to MUM and AA
            // on the PS page this is generated elsewhere (in static docs)
            this.generateNextAnalyticsSearchId();

            // Action Type "Redirect" is used for Lumino
            if(this._searchActionType === Constants.SEARCH_ACTION_TYPE_REDIRECT) {
                this.setIsPublic(true);
                this.setSource("lumino");
            }
        }


        if(formInfo) {
            this.initWidgetForm(formInfo, googleApiKey, verticalOrientation, showHeaderText, uniqueId);
        }
    }

    initMUMPublicSessionId() {
        if(this._widgetInstance.hasAttribute('data-lumino-mum-public-session-id')) {
            sessionStorage.setItem(Constants.SESSIONATTRIBUTE_MUM_PUBLIC_SESSION_ID, this._widgetInstance.getAttribute('data-lumino-mum-public-session-id'));
            this.setMumPublicSessionId(sessionStorage.getItem(Constants.SESSIONATTRIBUTE_MUM_PUBLIC_SESSION_ID));
            return;
        }

        const previouslyStoredPublicSession = sessionStorage.getItem(Constants.SESSIONATTRIBUTE_MUM_PUBLIC_SESSION_ID);
        if(previouslyStoredPublicSession === null) {
            sessionStorage.setItem(Constants.SESSIONATTRIBUTE_MUM_PUBLIC_SESSION_ID, Utils.generateUUID());
        }

        this.setMumPublicSessionId(sessionStorage.getItem(Constants.SESSIONATTRIBUTE_MUM_PUBLIC_SESSION_ID));
    }

    setSearchContextLocation(value) {
        this._searchContextLocation = value;
    }

    setVersion(value) {
        this._version = value;
    }

    triggerGeoLocationClickAnalytics () {

        AALogger.updateAnalyticsSearchID(this.getNextAnalyticsSearchId());
        AALogger.logAAEvent({
            ev_type: 'other',
            ev_action: 'clk',
            ev_title: 'geolocation_click'
        });
    }

    hasMumEnabled() {
        return !!this._mumLogger;
    }

    sendSearchKeywordEvent(trigger, selectedSuggestion, isExactMatch) {
        const eventDetail = {};

        eventDetail.textBoxValue = this.getForm().getSearchedText();
        eventDetail.searchModesEnabled = this.getSearchModes();
        eventDetail.trigger = trigger;
        eventDetail.unixTimestamp = new Date().getTime();


        if(selectedSuggestion) {

            // for related terms, you don't want to consider them exact match
            if(isExactMatch && selectedSuggestion.getMatchType() === Constants.SUGGESTION_MATCH_TYPE_LABEL) {
                eventDetail.suggestionSection = Constants.SUGGESTION_SECTION_EXACT_MATCH;
            } else {
                eventDetail.suggestionSection = selectedSuggestion.getSection();
            }
            eventDetail.suggestionType = selectedSuggestion.getType();
            eventDetail.suggestionValue = selectedSuggestion.getValue();
            eventDetail.relatedTerms = (selectedSuggestion.getMatchType() === Constants.SUGGESTION_MATCH_TYPE_RELATED_TERM);
        }

        if(this.getForm().getLastSuggestionsViewModel()) {
            eventDetail.suggestedSpecialties = this.getForm().getLastSuggestionsViewModel().getValuesForSectionType(Constants.SUGGESTION_SECTION_SUGGESTED_SPECIALTIES);
            eventDetail.suggestedConditions = this.getForm().getLastSuggestionsViewModel().getValuesForSectionType(Constants.SUGGESTION_SECTION_SUGGESTED_CONDITIONS);
            eventDetail.suggestedServices = this.getForm().getLastSuggestionsViewModel().getValuesForSectionType(Constants.SUGGESTION_SECTION_SUGGESTED_SERVICES);
        }

        const info = new MUMSearchKeywordInfo(eventDetail.popularSpecialties,
            eventDetail.relatedSpecialties,
            eventDetail.relatedTerms,
            eventDetail.searchModesEnabled,
            this._version,
            eventDetail.suggestionSection,
            eventDetail.suggestionType,
            eventDetail.suggestionValue,
            eventDetail.suggestedConditions,
            eventDetail.suggestedSpecialties,
            eventDetail.suggestedServices,
            eventDetail.textBoxValue,
            eventDetail.trigger,
            eventDetail.unixTimestamp);

        this._mumLogger.logSearchKeywordEvent(info.getEventInfo());
    }


    sendSearchErrorEvent(attempt) {

        let selectedSuggestionSection;
        let selectedSuggestionType;
        let selectedSuggestionValue;
        if(attempt.getSuggestionToUse()) {
            if(attempt.isExactMatch() && attempt.getSuggestionToUse().getMatchType() === Constants.SUGGESTION_MATCH_TYPE_LABEL) {
                selectedSuggestionSection = Constants.SUGGESTION_SECTION_EXACT_MATCH;
            } else {
                selectedSuggestionSection = attempt.getSuggestionToUse().getSection();
            }
            selectedSuggestionType = attempt.getSuggestionToUse().getType();
            selectedSuggestionValue = attempt.getSuggestionToUse().getValue();
        }

        const info = new MUMSearchErrorInfo(selectedSuggestionSection, selectedSuggestionType, selectedSuggestionValue,
            attempt.getSearchedText(), attempt.getLocation(), attempt.getErrorCodes(), new Date().getTime());

        this._mumLogger.logSearchErrorEvent(info.getEventInfo());
    }

    setAccessChannelCodeOverride(value) {
        this._mumLogger.setAccessChannelCodeOverride(value);
    }

    setSource(value) {
        this._source = value;
        this._mumLogger.setSource(value);
    }

    setIsPublic(value) {
        this._mumLogger.setIsPublic(value);
    }

    setMumPublicSessionId(value) {
        this._mumLogger.setMumPublicSessionId(value);
    }

    getSource() {
        return this._source;
    }

    getNextAnalyticsSearchId() {
        return this._mumLogger.getSearchId();
    }

    setNextAnalyticsSearchId(value) {
        this._mumLogger.setSearchId(value);
    }

    // AA tag 4.1 click on search button
    triggerSearchClickAnalytics(attempt) {

        AALogger.updateAnalyticsSearchID(this._mumLogger.getSearchId());

        let geolocationMethod;
        let suggestionValue;
        let errorCode;

        if(!attempt.getIsLocationValid()) {
            geolocationMethod = "";
        } else if (attempt.getLocation() === this.getForm().getGeolocationAddress()) {
            geolocationMethod = "browser_detection";
        } else {
            geolocationMethod = "manual";
        }

        if (attempt.getSuggestionToUse()) {
            suggestionValue = attempt.getSuggestionToUse().getValue();
        } else {
            suggestionValue = attempt.getSearchedText();
        }

        if(attempt.getErrorCodes().length > 0) {
            errorCode = attempt.getErrorCodes().join(",");
        } else {
            errorCode = "0";
        }

        let suggestionSectionAndType;
        if(attempt.getErrorCodes().includes(Constants.SEARCH_ERROR_CODE_INVALID_SEARCH_TERM) ||
            attempt.getErrorCodes().includes(Constants.SEARCH_ERROR_CODE_SUGGESTION_NOT_SELECTED)) {
            suggestionSectionAndType = "error";
        } else if(attempt.isExactMatch() && attempt.getSuggestionToUse().getMatchType() === Constants.SUGGESTION_MATCH_TYPE_LABEL) {

            suggestionSectionAndType = Constants.SUGGESTION_SECTION_EXACT_MATCH + ":" + attempt.getSuggestionToUse().getType();
        } else {
            suggestionSectionAndType = attempt.getSuggestionToUse().getSection() + ":" + attempt.getSuggestionToUse().getType();
        }

        AALogger.logAAEvent({
            ev_type: "other",
            ev_action: "clk",
            ev_title: "provider search - search button:" + suggestionSectionAndType,
            ev_data_one: suggestionValue + ":" + attempt.getLocation(),
            ev_data_two: geolocationMethod + ":" + this._searchContextLocation,
            ev_data_three: errorCode
        });
    }

    logNavigationEvent(actionCode) {
        this.getMUMLogger().logNavigationEvent(actionCode, this._searchContextLocation);
    }

    getMUMLogger() {
        return this._mumLogger;
    }

    triggerFavoritesLinkAnalytics() {

        this.logNavigationEvent("ViewFavourite");
        AALogger.updateAnalyticsSearchID(this._mumLogger.getSearchId());
        AALogger.logAAEvent({
            ev_type: "other",
            ev_action: "clk",
            ev_title: "provider search - search button",
            ev_data_one: "Favourites - View All Fav Link"
        });
    }


    generateNextAnalyticsSearchId() {
        this.setNextAnalyticsSearchId(Utils.generateUUID());

        return this.getNextAnalyticsSearchId();
    }

    fetchInitializationDataAndInit() {

        const apiUrl = this.getFormInfoApiUrlPath();
        if (apiUrl) {
            this.fetchInitializationDataFromAPI(apiUrl);
        }
    }


    getFormInfoApiUrlPath() {

        let apiUrl;

        if(this._searchActionType === Constants.SEARCH_ACTION_TYPE_FORM_ACTION) {
            apiUrl = this.getSecureProviderSearchBasePath();
        } else if(this._searchActionType === Constants.SEARCH_ACTION_TYPE_REDIRECT) {
            apiUrl = this.getLuminoProviderSearchBasePath();
        }

        apiUrl += `/util/formInfo?locale=${this._languageCode}_CA`;

        if(this._searchActionType === Constants.SEARCH_ACTION_TYPE_REDIRECT) {
            apiUrl += `&source=lumino`;
        }

        return apiUrl;
    }

    getSearchActionType() {
        return this._searchActionType;
    }

    initWidgetForm(formInfo, googleApiKey, verticalOrientation, showHeaderText, uniqueId) {
        if(googleApiKey) {
            this._googleAPIKey = googleApiKey;
        }
        if(verticalOrientation !== null && verticalOrientation !== undefined) {
            this._verticalOrientation = verticalOrientation;
        }
        if(showHeaderText !== null && showHeaderText !== undefined) {
            this._showHeaderText = showHeaderText;
        }
        if(uniqueId) {
            this._uniqueId = uniqueId;
        }

        super.initWidgetWithData(formInfo);
        this.initWidgetFormWithFormInfo(formInfo);
    }

    initWidgetFormWithFormInfo(formInfo) {

        // check to make sure widget isn't already initialized
        if(this._widgetInstance.childElementCount > 0 && this._widgetInstance.firstElementChild.localName === "form"){
            return;
        }

        const customizationOptions = {
            showMyAppointments: formInfo.showMyAppointmentsLink,
            defaultSpecialty: this._defaultSpecialty,
            defaultLocation: this._defaultLocation,
            defaultCondition: this._defaultCondition,
            defaultService: this._defaultService,
            verticalOrientation: this._verticalOrientation,
            showHeaderText: this._showHeaderText,
            searchModes: this._searchModes,
            headerText: this._headerText,
            footerText: this._footerText,
            topLogo: this._topLogo,
            headerLogo: this._headerLogo,
            locationPlaceholderText: this._locationPlaceholderText,
            locationLabelText: this._locationLabelText,
            specialtySearchPlaceholderText: this._specialtySearchPlaceholderText,
            specialtySearchLabelText: this._specialtySearchLabelText,
            textSearchPlaceholderText: this._textSearchPlaceholderText,
            textSearchLabelText: this._textSearchLabelText,
            enableFavourites: this._enableFavourites,
            theme: this._theme,
            useConfigSpecialtySorting: this._useConfigSpecialtySorting
        };

        const keywordLogging = {
            keywordLoggingEveryNCharacters: formInfo.keywordLoggingEveryNCharacters,
        };
        this.setKeywordLoggerPauseTimeout(formInfo.keywordLoggingPause);


        this._form = new InternalPSWidgetForm(this._languageCode, this, formInfo.suggestions, formInfo.favoritesCount, this._googleAPIKey, this._uniqueId, customizationOptions, keywordLogging);
        this._form.bindToDOM(this._widgetInstance);
    }

    setKeywordLoggerPauseTimeout(keywordLoggingPause) {
        // The 'keywordLoggingPause' is used inside the debounce function.
        // It is referenced by JS as soon as the Internal Form is created, before the constructor is called.
        // The only way to pass the delay time is with a global variable.
        window.PSLauncherKeywordLoggingPause = keywordLoggingPause;
    }

    isValidWidgetParameters() {
        return true;
    }

    getSponsorId() {
        return this.getMainDivElement().getAttribute('data-lumino-sponsorid');
    }

    getLinkedFrom() {
        return this._linkedFrom;
    }

    initWidgetWithData(formInfo) {
        super.initWidgetWithData(formInfo);
        this.initWidgetFormWithFormInfo(formInfo);
    }

    createSearchEvent(attempt) {
        const event = super.createSearchEvent(attempt);
        event.detail.geolocationAddress = this.getForm().getGeolocationAddress();

        return event;
    }

    executeSearch(attempt) {
        if (this._searchActionType === Constants.SEARCH_ACTION_TYPE_FORM_ACTION) {
            this._form.prepareFormForSearchAndSubmit(attempt);

        } else if (this._searchActionType === Constants.SEARCH_ACTION_TYPE_REDIRECT) {
            this.executeLuminoSearch(attempt);

        } else if(this._searchActionType === Constants.SEARCH_ACTION_TYPE_EVENT) {
            const event = this.createSearchEvent(attempt);
            this._form.getDOMElement().dispatchEvent(event);
        }
    }

    executeLuminoSearch(attempt) {
        const luminoURL = this.createLuminoURL(attempt);

        if(this._targetNewTab) {
            window.open(luminoURL);
        } else {
            window.location.href = luminoURL;
        }
    }

    createLuminoURL(attempt) {
        const suggestionToUse = attempt.getSuggestionToUse();
        let redirectURL = super.createLuminoURL(suggestionToUse);

        if(this.getForm().getGeolocationAddress()) {
            redirectURL += `&geolocationaddress=${encodeURIComponent(this.getForm().getGeolocationAddress())}`;
        }

        if(this.getLinkedFrom()) {
            redirectURL += `&linkedfrom=${encodeURIComponent(this.getLinkedFrom())}`;
        }

        redirectURL += `&skipinitialsearchaalog=true&skipinitialsearchmumlog=true&analyticssearchid=${encodeURIComponent(this.getNextAnalyticsSearchId())}&suggestionsection=${this.getSuggestedSectionFromAttemptInformation(attempt)}`;

        return redirectURL;
    }

    getSuggestedSectionFromAttemptInformation(attempt) {
        return attempt.isExactMatch() ? Constants.SUGGESTION_SECTION_EXACT_MATCH : attempt.getSuggestionToUse().getSection();
    }

    getSearchHost() {

        if(this._testHost) {

            return this._testHost;
        }

        return "";
    }

    goToFavourites() {
        this.triggerFavoritesLinkAnalytics();
        if(this._searchActionType === Constants.SEARCH_ACTION_TYPE_FORM_ACTION) {
            this._form.prepareFormForFavouritesAndSubmit();

        } else if(this._searchActionType === Constants.SEARCH_ACTION_TYPE_EVENT) {
            const event = this.createSearchEvent(Constants.SEARCH_MODE_FAVOURITES);
            this._form.getDOMElement().dispatchEvent(event);
        }
    }

    goToMyAppointments() {
        window.location.href = this.getSecureBasePath() + "/bookinghub/myAppointments";
    }

    getSecureProviderSearchBasePath() {
        return this.getSecureBasePath() + "/providersearch";
    }

    getSecureBasePath() {
        let path = "";
        if(super.isLocalhost()) {
            path = "http://localhost:8000";
        }

        path += "/healthcommunity/req/secure/pphp";

        return path;
    }

    getLuminoProviderSearchBasePath() {

        let path = "";

        if(this._testHost) {
            path = this._testHost;
        }
        path += "/healthcommunity/pub/req/providersearch";

        return path;
    }

    getSecureProviderSearchPath() {
        return `${this.getSecureProviderSearchBasePath()}/searchProvider?locale=${this._languageCode}_CA`;
    }
}