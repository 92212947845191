'use strict';

export class MUMSearchKeywordInfo {
    constructor(popularSpecialties, relatedSpecialties, selectedSuggestionIsFromRelatedTerms, searchModesEnabled, searchWidgetVersion,
                selectedSuggestionSection, selectedSuggestionType,
                selectedSuggestionValue, suggestedConditions, suggestedSpecialties, suggestedServices, textBoxValue,
                trigger, unixTimestamp) {

        this._popularSpecialties = popularSpecialties;
        this._relatedSpecialties = relatedSpecialties;
        this._selectedSuggestionIsFromRelatedTerms = selectedSuggestionIsFromRelatedTerms;
        this._searchModesEnabled = searchModesEnabled
        this._searchWidgetVersion = searchWidgetVersion;
        this._selectedSuggestionSection = selectedSuggestionSection;
        this._selectedSuggestionType = selectedSuggestionType;
        this._selectedSuggestionValue = selectedSuggestionValue;
        this._suggestedConditions = suggestedConditions;
        this._suggestedSpecialties = suggestedSpecialties;
        this._suggestedServices = suggestedServices;
        this._textBoxValue = textBoxValue;
        this._trigger = trigger;
        this._unixTimestamp = unixTimestamp;

    }

    getEventInfo() {
        return {
            "popularSpecialties": this._popularSpecialties,
            "relatedSpecialties": this._relatedSpecialties,
            "selectedSuggestionIsFromRelatedTerms": this._selectedSuggestionIsFromRelatedTerms,
            "searchModesEnabled": this._searchModesEnabled,
            "searchWidgetVersion": this._searchWidgetVersion,
            "selectedSuggestionSection": this._selectedSuggestionSection,
            "selectedSuggestionType": this._selectedSuggestionType,
            "selectedSuggestionValue": this._selectedSuggestionValue,
            "suggestedConditions": this._suggestedConditions,
            "suggestedSpecialties": this._suggestedSpecialties,
            "suggestedServices": this._suggestedServices,
            "textBoxValue": this._textBoxValue,
            "trigger": this._trigger,
            "unixTimestamp": this._unixTimestamp
        };
    }
}