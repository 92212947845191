'use strict';

import '../../scss/internal/styles-loader.scss';
import {InternalPSWidgetController} from "./internal-controller";

export class InternalPSWidgetBase {
    constructor() {
        window.internalFormWidgetInit = function() {
            const containerElement = document.body.querySelectorAll("div[data-lumino-widget='ProviderSearchLauncher']");

            containerElement.forEach((internalWidgetInstance) => {

                // Don't load widget again if 'luminoFormWidgetInit' is called but widget was already initialized
                if(internalWidgetInstance.hasAttribute("data-lumino-widget-initialized")) {
                    return;
                }

                const internalWidgetController = new InternalPSWidgetController(internalWidgetInstance);

                if(internalWidgetController.isValidWidgetParameters()) {
                    internalWidgetController.fetchInitializationDataAndInit();
                } else {
                    internalWidgetController.removeWidgetOnError("An error validating Internal Widget has occurred, exiting.");
                }
            });
        };
    }
}