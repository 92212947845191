'use strict';

export class MUMSearchErrorInfo {
    constructor(selectedSuggestionSection, selectedSuggestionType, selectedSuggestionValue, searchTextBoxValue, location,
                errorCodes, unixTimestamp) {

        this._selectedSuggestionSection = selectedSuggestionSection;
        this._selectedSuggestionType = selectedSuggestionType;
        this._selectedSuggestionValue = selectedSuggestionValue;
        this._searchTextBoxValue = searchTextBoxValue;
        this._errorCodes = errorCodes;
        this._location = location;
        this._unixTimestamp = unixTimestamp;
    }

    getEventInfo() {
        return {
            "selectedSuggestionSection": this._selectedSuggestionSection,
            "selectedSuggestionType": this._selectedSuggestionType,
            "selectedSuggestionValue": this._selectedSuggestionValue,
            "searchTextBoxValue": this._searchTextBoxValue,
            "location": this._location,
            "errorCodes": this._errorCodes,
            "unixTimestamp": this._unixTimestamp
        };
    }
}