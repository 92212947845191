'use strict';

import {Constants} from "./constants";

export class MUMLogger {

    #searchId;
    #source;
    #accessChannelCodeOverride;
    #lastMUMEvent = "";
    #lastMUMEventTS = null;
    #lastELogEvent = "";
    #lastELogEventTS = null;
    #isPublic;
    #mumPublicSessionId;

    constructor() {
    }

    setIsPublic(value) {
        this.#isPublic = value;
    }

    getIsPublic() {
        return this.#isPublic;
    }

    setMumPublicSessionId(value) {
        this.#mumPublicSessionId = value;
    }

    getMumPublicSessionId() {
        return this.#mumPublicSessionId;
    }

    setSource(value) {
        this.#source = value;
    }

    getSource() {
        return this.#source;
    }

    setSearchId(value) {
        this.#searchId = value;
    }

    getSearchId() {
        return this.#searchId;
    }

    setAccessChannelCodeOverride(value) {
        this.#accessChannelCodeOverride = value;
    }

    logSearchKeywordEvent(eventInfo) {

        let urlToUse = this.getURLBase() + "/mum/searchKeywordEvent";
        if(this.#source) {
            urlToUse += "?source=" + this.#source;
        }

        const requestData = {
            "popularSpecialties": eventInfo.popularSpecialties,
            "relatedSpecialties": eventInfo.relatedSpecialties,
            "selectedSuggestionIsFromRelatedTerms": eventInfo.selectedSuggestionIsFromRelatedTerms,
            "searchModesEnabled": eventInfo.searchModesEnabled,
            "searchWidgetVersion": eventInfo.searchWidgetVersion,
            "selectedSuggestionSection": eventInfo.selectedSuggestionSection,
            "selectedSuggestionType": eventInfo.selectedSuggestionType,
            "selectedSuggestionValue": eventInfo.selectedSuggestionValue,
            "suggestedConditions": eventInfo.suggestedConditions,
            "suggestedSpecialties": eventInfo.suggestedSpecialties,
            "suggestedServices": eventInfo.suggestedServices,
            "textBoxValue": eventInfo.textBoxValue,
            "trigger": eventInfo.trigger,
            "searchId": this.#searchId,
            "accessChannelCodeOverride": this.#accessChannelCodeOverride,
            "unixTimestamp": eventInfo.unixTimestamp
        };

        if(this.#isPublic) {
            requestData.mumPublicSessionId = this.#mumPublicSessionId;
        }

        if(requestData.textBoxValue.length < Constants.SUGGESTION_MIN_INPUT_LENGTH) {
            // Don't log anything less than minimum input length
            return;
        }

        const xhttp = new XMLHttpRequest();
        xhttp.open('POST', urlToUse, true);
        xhttp.setRequestHeader("Content-type", "application/json; charset=utf-8");
        xhttp.onreadystatechange = () => {
            if (xhttp.readyState === 4 && xhttp.status === 200) {
                // Do nothing for now
            }
        };
        xhttp.onerror = () => {
            console.log("logSearchKeywordEvent failed");
        };
        xhttp.onloadend = () => {
            // Do nothing
        };

        xhttp.send(JSON.stringify(requestData));
    }

    logSearchErrorEvent(eventInfo) {

        let urlToUse = this.getURLBase() + "/mum/searchErrorEvent";
        if(this.#source) {
            urlToUse += "?source=" + this.#source;
        }

        let requestData = {
            "selectedSuggestionSection": eventInfo.selectedSuggestionSection,
            "selectedSuggestionType": eventInfo.selectedSuggestionType,
            "selectedSuggestionValue": eventInfo.selectedSuggestionValue,
            "searchTextBoxValue": eventInfo.searchTextBoxValue,
            "location": eventInfo.location,
            "errorCodes": eventInfo.errorCodes,
            "searchId": this.#searchId,
            "accessChannelCodeOverride": this.#accessChannelCodeOverride,
            "unixTimestamp": eventInfo.unixTimestamp
        };

        if(this.#isPublic) {
            requestData.mumPublicSessionId = this.#mumPublicSessionId;
        }

        const xhttp = new XMLHttpRequest();
        xhttp.open('POST', urlToUse, true);
        xhttp.setRequestHeader("Content-type", "application/json; charset=utf-8");
        xhttp.onreadystatechange = () => {
            // Do nothing
        };
        xhttp.onerror = () => {
            console.log("logSearchErrorEvent failed");
        };
        xhttp.onloadend = () => {
            // Do nothing
        };

        xhttp.send(JSON.stringify(requestData));
    }

    getURLBase() {
        let urlBase = "/healthcommunity";
        if (!this.#isPublic) {

            urlBase += "/req/secure/pphp"
        } else {
            urlBase += "/pub/req";
        }

        urlBase += "/providersearch";

        return urlBase;
    }

    logNavigationEvent(actionCode, contextLocation) {

        if (this.#isPublic) {
            return;
        }

        if (this.isMultipleClick(actionCode, true)) {
            return;
        }

        const urlToUse = this.getURLBase() + "/mum/doNavigationEventLogging?actionCode=" +
            encodeURI(actionCode) + "&contextLocation=" +
            encodeURI(contextLocation);

        const xhttp = new XMLHttpRequest();
        xhttp.open('GET', urlToUse, true);
        xhttp.setRequestHeader("Content-type", "application/json; charset=utf-8");

        xhttp.send();
    }

    isMultipleClick(key, isNavigationEvent) {
        if(isNavigationEvent) {
            if (key ===  this.#lastELogEvent && this.timestampMeansDuplicate(this.#lastELogEventTS, Date.now())) {
                return true; // multiple clicks captured
            } else {
                this.#lastELogEvent = key;
                this.#lastELogEventTS = Date.now();

                return false;
            }
        } else {
            if (key ===  this.#lastMUMEvent && this.timestampMeansDuplicate(this.#lastMUMEventTS, Date.now())) {
                return true; // multiple clicks captured
            } else {
                this.#lastMUMEvent = key;
                this.#lastMUMEventTS = Date.now();

                return false;
            }
        }
    }

    timestampMeansDuplicate(previousTimestamp, timestamp) {
        return (timestamp - previousTimestamp < 1000);
    }
}
