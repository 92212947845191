'use strict';

import {PSWidgetBasicComponent} from "../basic-component";

export class InternalPSWidgetFavouritesLink extends PSWidgetBasicComponent {
    #favouritesCount;
    #formSubmitTimer;
    #formSubmitReady = true;

    constructor(language, favouritesCount) {
        super(language);
        this.#favouritesCount = favouritesCount;
    }

    bindToDOM(parentDOMElement) {
        super.bindToDOM(parentDOMElement);

        this._domElement.addEventListener('click', (e) => {
            if (e.which === 13 || e.which === 32 || e.type === 'click') {
                e.preventDefault();

                if(this.#formSubmitReady) {
                    this._domElement.dispatchEvent(new CustomEvent('goToFavourites', { bubbles: true }));
                    this.#formSubmitReady = false;
                }

                clearTimeout(this.#formSubmitTimer);
                this.#formSubmitTimer = setTimeout(() => {
                    this.#formSubmitReady = true;
                }, 1000);
            }
        });
    }

    getFavouritesCount() {
        return this.#favouritesCount;
    }

    updateFavouritesCount(favouritesCount) {
        this.#favouritesCount = favouritesCount;
        this.getDOMElement().querySelector('[data-lumino-widget-id="lumino-widget-favourites-count"]').innerHTML = this.#favouritesCount;
    }

    render() {
        return `<button type="button" class="lumino-widget-favourites-link slf-blue" data-lumino-widget-id="lumino-widget-favourites-link">
                   <span class="lumino-widget-favourites-icon"><img src="${ require('../../images/lumino_favorites_icon.svg') }" alt=""></span>
                   <span data-lumino-widget-id="lumino-widget-favourites-label" class="lumino-widget-favourites-label">${this.getLocalizedLabel("favouritesLinkText")} (<span data-lumino-widget-id="lumino-widget-favourites-count">${ this.getFavouritesCount() }</span>)</span>
                </button>`;
    }

    addLocalizedLabels() {
        this.addLocalizedLabel("favouritesLinkText","My favourites","Mes favoris");
    }
}
