'use strict';

export default {
    lastAAEvent: "",
    lastAAEventTS: null,

    logAAEvent: function (aaTagObject) {

        let multiClickKey = "";

        for (let key in aaTagObject) {
            multiClickKey += aaTagObject[key];
        }

        if (this.isMultipleClick(multiClickKey)) {
            return;
        }

        try {
            utag.link(aaTagObject);
        } catch (err) {
            console.error(err.message);
        }
    },



    isMultipleClick: function (key) {
        if (key === this.lastAAEvent && this.timestampMeansDuplicate(this.lastAAEventTS, Date.now())) {
            return true; // multiple clicks captured
        } else {
            this.lastAAEvent = key;
            this.lastAAEventTS = Date.now();

            return false;
        }
    },

    timestampMeansDuplicate: function (previousTimestamp, timestamp) {
        return (timestamp - previousTimestamp < 1000);
    },

    updateAnalyticsSearchID: function(searchId) {
        if (typeof utag_data !== 'undefined' && utag_data) {
            utag_data.ps_search_id = searchId;
        }
    }
};
